import React from 'react'
import { LayoutV2 } from '../../components/common'
import { Helmet } from 'react-helmet'
import { CaliforniaPrivacyNotice } from '../../components/CaliforniaPrivacyNotice'
import { withPrefix } from 'gatsby'

const CaliforniaPrivacyNoticePage = ({ data = {} }) => {
  return (
    <LayoutV2 query={data} title="PGA.Coach">
      <Helmet>
        <script
          src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          charset="UTF-8"
          id="otprivacy-notice-script"
        >
          {`settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"`}
        </script>
        <script src={withPrefix('script2.js')} type="text/javascript" />
      </Helmet>
      <CaliforniaPrivacyNotice />
    </LayoutV2>
  )
}

export default CaliforniaPrivacyNoticePage
