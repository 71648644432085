import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

export const CaliforniaPrivacyNotice = () => {
  return (
    <Container style={{ marginTop: '100px', marginBottom: '100px' }}>
      <Box display="flex" justifyContent="center" pt={8}>
        <Typography variant="h3" gutterBottom>
          California Privacy Notice
        </Typography>
      </Box>
      <div
        id="otnotice-330685bd-06b1-475f-b088-666c20f337d9"
        className="otnotice"
      />
    </Container>
  )
}

export default CaliforniaPrivacyNotice
